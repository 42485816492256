<template>
  <v-dialog v-model="dialog" persistent max-width="30%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar"
                ? "Editar Pagamentos"
                : "Cadastrar Pagamentos"
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="12" lg="12">
              <v-text-field
                v-model="formTipoPagamento.tipo_pagamento"
                label="Tipo Pagamento"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-text-field
                v-model="formTipoPagamento.descricao"
                label="Descrição"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="4">
              <v-autocomplete
                label="Status"
                :items="[
                  { text: 'Sim', value: 'S' },
                  { text: 'Não', value: 'N' }
                ]"
                :rules="[rules.required]"
                v-model="formTipoPagamento.ativo"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? put() : send()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";

import campanha from "@/services/http/campanhaService";

export default {
  name: "ModalTipoDado",
  mixins: [rules],

  components: {},

  props: {
    tipoPagamento: {
      type: Object,
      default: () => ({})
    },

    dialog: {
      type: Boolean,
      default: false
    },
    labelBtn: {
      type: String
    }
  },

  data() {
    return {
      formTipoPagamento: {}
    };
  },

  watch: {
    tipoPagamento(value) {
      this.formTipoPagamento = value;
    },
    labelBtn(value) {
      this.locked = value == "Editar" ? true : false;
    }
  },

  methods: {
    async put() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await campanha()
          .tipoPagamento(this.formTipoPagamento.id_tipo_pagamento)
          .update(this.formTipoPagamento, {
            notification: true,
            menssage: "Pagemento Editado com sucesso!"
          });
      }
      this.close();
    },
    async send() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await campanha()
          .tipoPagamento()
          .store(this.formTipoPagamento, {
            notification: true,
            menssage: "Pagamento cadastrado com sucesso!"
          });
      }
      this.close();
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    }
  }
};
</script>
