<template>
  <div>
    <BaseTable
      :headers="headers"
      :loading="loading"
      :paginate="true"
      :items="items"
      sort-by="tipo_pagamento"
      class="table-shadow"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Tipos de Pagamentos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="582"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <Filtro @selectedFilters="fetchTipoPagamento((filters = $event))" />
        </v-toolbar>
      </template>

      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip :color="statusFormated(item).color" dark>
          {{ statusFormated(item).text }}
        </v-chip>
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <!--  <v-btn
          icon
          v-can-access="583"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-can-access="585"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->
        <IconBottom :name="'edit'" v-can-access="583" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="585"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalTipoPagamento
      :tipoPagamento="tipoPagamento"
      :dialog="dialog"
      :labelBtn="labelBtn"
      @close="refresh()"
    />
  </div>
</template>

<script>
import IconBottom from "@/components/shared/bottons/IconBottom";
import BaseTable from "@/components/shared/NewBaseTable";
import Filtro from "./Filtro";
import ModalTipoPagamento from "./ModalTipoPagamento";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import campanha from "@/services/http/campanhaService";

export default {
  name: "Tabela",

  components: {
    BaseTable,
    ModalTipoPagamento,
    Filtro,
    RegisterBottom,
    IconBottom
  },

  data() {
    return {
      filters: {},
      dialog: false,
      labelBtn: "",
      tipoPagamento: {},
      headers: [
        { text: "Tipo Pagamento", value: "tipo_pagamento" },
        { text: "Descrição", value: "descricao" },
        { text: "Status", value: "ativo" },
        { text: "Ações", value: "acoes" }
      ],
      items: [],
      loading: false
    };
  },

  methods: {
    statusFormated(tipoPagamento) {
      return {
        color: tipoPagamento.ativo === "S" ? "green" : "red",
        text: tipoPagamento.ativo === "S" ? "Ativo" : "Inativo"
      };
    },

    async fetchTipoPagamento(filters) {
      this.loading = "true";
      const { data } = await campanha()
        .tipoPagamento()
        .show({
          per_page: -1,
          ativo: "S",
          ...filters
        });
      this.items = data.data;
      this.loading = "false";
    },
    editItem(item) {
      this.tipoPagamento = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    refresh() {
      this.dialog = false;
      this.fetchTipoPagamento(this.filters);
    },
    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deseja deletar Parametro?",
          "Essa ação não pode ser desfeita"
        );
        campanha()
          .tipoPagamento(item.id_tipo_pagamento)
          .delete(
            {},
            {
              notification: true,
              message: "Meta deletada com sucesso!"
            }
          );
      } catch (error) {
        console.log(error);
      } finally {
        this.fetchTipoPagamento(this.filters);
      }
    }
  },
  async mounted() {
    await this.fetchTipoPagamento({ per_page: -1, ativo: "S" });
  }
};
</script>
