<template>
  <v-container class="container-card">
    <v-card>
      <Tabela />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Tabela from "@/components/parametros/tipoPagamento/Tabela";

export default {
  name: "TipoPagamento",

  components: {
    Tabela
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Consulta Tipo Pagamento");
  }
};
</script>
